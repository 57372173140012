import { CountUp } from "countup.js";
import $ from "jquery";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

global.$ = global.jQuery = $;
window.UIkit = UIkit;
UIkit.use(Icons);
window.initMap = function () {
  var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 13,

    // The latitude and longitude to center the map (always required)
    center: new google.maps.LatLng(48.865241909238904, 2.3730203516837207), // Kino

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            visibility: "off",
          },
          {
            hue: "#ff0000",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#d8d8d8",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 45,
          },
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#666666",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            visibility: "simplified",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ff0000",
          },
        ],
      },
      {
        featureType: "transit.station.bus",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "transit.station.rail",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#64bbff",
          },
          {
            visibility: "on",
          },
        ],
      },
    ],
  };
  if (document.getElementById("map")) {
    var mapElement = document.getElementById("map");
    var map = new google.maps.Map(mapElement, mapOptions);
    // Let's also add a marker while we're at it
    new google.maps.Marker({
      position: new google.maps.LatLng(48.865158, 2.372678),
      map: map,
      title: "Kino c'est par ici !",
    });
  }
};

$(document).ready(function () {
  addDevisScript();
  addCounterScript();
  initRestaurationSwicher();
});

function addDevisScript() {
  $("#devis a.cta").click(function (e) {
    e.preventDefault();
    $(this).hide();
    $(this)
      .parent(".uk-card")
      .css("padding", "20px")
      .css("justify-content", "flex-end");
    $(this)
      .parent()
      .parent()
      .removeClass()
      .addClass("uk-width-expand@m  uk-grid-margin");
    $(this).parent().find("h2").hide();
    $(this).parent().find("#sentence").hide();
    $(this).parent().find("#formulaire").find("textarea").css("height", "70px");
    $(this).parent().find("#formulaire").show();
    $(this).parent().find("a.uk-close").show();
    $("#dcp").hide();
    $("#timelapses").hide();
  });
  $("#devis a.uk-close").click(function (e) {
    $("#dcp").show();
    $("#timelapses").show();
    e.preventDefault();
    $(this).hide();
    $(this).parent().find("a.cta").show();
    $(this).parent().removeAttr("style");
    $(this)
      .parent()
      .parent()
      .removeClass()
      .addClass("uk-width-auto@m  uk-grid-margin");
    $(this).parent().find("h2").show();
    $(this).parent().find("#sentence").show();
    $(this).parent().find("#formulaire").hide();
    $(this).parent().find(".wpcf7-response-output").hide();
  });
}
function addCounterScript() {
  $(".count").each(function () {
    var target = $(this).find(".number").attr("id");
    var number = $(this).find(".number").attr("data-number");
    var demo = new CountUp(target, number);
    if (!demo.error) {
      demo.start();
    } else {
      console.error(demo.error);
    }
  });
}

function initRestaurationSwicher() {
  var dragging = false,
    scrolling = false,
    resizing = false;
  //cache jQuery objects
  var imageComparisonContainers = $(".cd-image-container");
  //check if the .cd-image-container is in the viewport
  //if yes, animate it
  checkPosition(imageComparisonContainers);
  $(window).on("scroll", function () {
    if (!scrolling) {
      scrolling = true;
      !window.requestAnimationFrame
        ? setTimeout(function () {
            checkPosition(imageComparisonContainers);
          }, 100)
        : requestAnimationFrame(function () {
            checkPosition(imageComparisonContainers);
          });
    }
  });

  //make the .cd-handle element draggable and modify .cd-resize-img width according to its position
  imageComparisonContainers.each(function () {
    var actual = $(this);
    drags(
      actual.find(".cd-handle"),
      actual.find(".cd-resize-img"),
      actual,
      actual.find('.cd-image-label[data-type="original"]'),
      actual.find('.cd-image-label[data-type="modified"]')
    );
  });

  //upadate images label visibility
  $(window).on("resize", function () {
    if (!resizing) {
      resizing = true;
      !window.requestAnimationFrame
        ? setTimeout(function () {
            checkLabel(imageComparisonContainers);
          }, 100)
        : requestAnimationFrame(function () {
            checkLabel(imageComparisonContainers);
          });
    }
  });

  function checkPosition(container) {
    container.each(function () {
      var actualContainer = $(this);
      if (
        $(window).scrollTop() + $(window).height() * 0.5 >
        actualContainer.offset().top
      ) {
        actualContainer.addClass("is-visible");
      }
    });

    scrolling = false;
  }

  function checkLabel(container) {
    container.each(function () {
      var actual = $(this);
      updateLabel(
        actual.find('.cd-image-label[data-type="modified"]'),
        actual.find(".cd-resize-img"),
        "left"
      );
      updateLabel(
        actual.find('.cd-image-label[data-type="original"]'),
        actual.find(".cd-resize-img"),
        "right"
      );
    });

    resizing = false;
  }

  //draggable funtionality - credits to http://css-tricks.com/snippets/jquery/draggable-without-jquery-ui/
  function drags(
    dragElement,
    resizeElement,
    container,
    labelContainer,
    labelResizeElement
  ) {
    dragElement
      .on("mousedown vmousedown", function (e) {
        dragElement.addClass("draggable");
        resizeElement.addClass("resizable");

        var dragWidth = dragElement.outerWidth(),
          xPosition = dragElement.offset().left + dragWidth - e.pageX,
          containerOffset = container.offset().left,
          containerWidth = container.outerWidth(),
          minLeft = containerOffset + 10,
          maxLeft = containerOffset + containerWidth - dragWidth - 10;

        dragElement
          .parents()
          .on("mousemove vmousemove", function (e) {
            if (!dragging) {
              dragging = true;
              !window.requestAnimationFrame
                ? setTimeout(function () {
                    animateDraggedHandle(
                      e,
                      xPosition,
                      dragWidth,
                      minLeft,
                      maxLeft,
                      containerOffset,
                      containerWidth,
                      resizeElement,
                      labelContainer,
                      labelResizeElement
                    );
                  }, 100)
                : requestAnimationFrame(function () {
                    animateDraggedHandle(
                      e,
                      xPosition,
                      dragWidth,
                      minLeft,
                      maxLeft,
                      containerOffset,
                      containerWidth,
                      resizeElement,
                      labelContainer,
                      labelResizeElement
                    );
                  });
            }
          })
          .on("mouseup vmouseup", function (e) {
            dragElement.removeClass("draggable");
            resizeElement.removeClass("resizable");
          });
        e.preventDefault();
      })
      .on("mouseup vmouseup", function (e) {
        dragElement.removeClass("draggable");
        resizeElement.removeClass("resizable");
      });
  }

  function animateDraggedHandle(
    e,
    xPosition,
    dragWidth,
    minLeft,
    maxLeft,
    containerOffset,
    containerWidth,
    resizeElement,
    labelContainer,
    labelResizeElement
  ) {
    var leftValue = e.pageX + xPosition - dragWidth;
    //constrain the draggable element to move inside his container
    if (leftValue < minLeft) {
      leftValue = minLeft;
    } else if (leftValue > maxLeft) {
      leftValue = maxLeft;
    }

    var widthValue =
      ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth +
      "%";

    $(".draggable")
      .css("left", widthValue)
      .on("mouseup vmouseup", function () {
        $(this).removeClass("draggable");
        resizeElement.removeClass("resizable");
      });

    $(".resizable").css("width", widthValue);

    updateLabel(labelResizeElement, resizeElement, "left");
    updateLabel(labelContainer, resizeElement, "right");
    dragging = false;
  }

  function updateLabel(label, resizeElement, position) {
    if (position == "left") {
      label.offset().left + label.outerWidth() <
      resizeElement.offset().left + resizeElement.outerWidth()
        ? label.removeClass("is-hidden")
        : label.addClass("is-hidden");
    } else {
      label.offset().left >
      resizeElement.offset().left + resizeElement.outerWidth()
        ? label.removeClass("is-hidden")
        : label.addClass("is-hidden");
    }
  }
}
